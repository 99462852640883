import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Section extends React.PureComponent {
  static sizes = ['medium', 'large'];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    stule: PropTypes.object,
    size: PropTypes.oneOf(Section.sizes),
    renderAs: PropTypes.any.isRequired
  };

  static defaultProps = {
    renderAs: 'section'
  };

  render() {
    const {
      children,
      className,
      renderAs,
      size,
      ...props
    } = this.props;

    let Element = renderAs;

    return (
      <Element
        {...props}
        className={classNames('section', className, {
          [`is-${size}`]: size
        })}
      >
        {children}
      </Element>
    );
  }
}

export default Section;