import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import constants from './../constants';
import NavbarContext from './context';
import NavbarBrand from './components/Brand';
import NavbarBurger from './components/Burger';
import NavbarContainer from './components/Container';
import NavbarItem from './components/Item';
import NavbarMenu from './components/Menu';

class Navbar extends React.PureComponent {
  static Brand = NavbarBrand;
  static Burger = NavbarBurger;
  static Container = NavbarContainer;
  static Item = NavbarItem;
  static Menu = NavbarMenu;

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    transparent: PropTypes.bool.isRequired,
    color: PropTypes.oneOf(constants.COLORS),
    active: PropTypes.bool.isRequired
  };

  static defaultProps = {
    transparent: false,
    active: false
  };

  render() {
    const {
      children,
      className,
      transparent,
      color,
      active,
      ...props
    } = this.props;

    return (
      <NavbarContext.Provider value={active}>
        <nav
          {...props}
          role='navigation'
          className={classNames('navbar', className, {
            'is-transparent': transparent,
            [`is-${color}`]: color
          })}
        >
          {children}
        </nav>
      </NavbarContext.Provider>
    );
  }
}

export default Navbar;