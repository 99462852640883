import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Heading extends React.PureComponent {
  static weights = ['light', 'normal', 'semibold', 'bold'];
  static sizes = [1, 2, 3, 4, 5, 6];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    renderAs: PropTypes.any,
    size: PropTypes.oneOf(Heading.sizes).isRequired,
    weight: PropTypes.oneOf(Heading.weights),
    subtitle: PropTypes.bool.isRequired,
    heading: PropTypes.bool.isRequired,
    spaced: PropTypes.bool.isRequired
  };

  static defaultProps = {
    renderAs: 'h1',
    size: 3,
    subtitle: false,
    heading: false,
    spaced: false
  };

  render() {
    const {
      children,
      className,
      renderAs,
      size,
      weight,
      subtitle,
      heading,
      spaced,
      ...props
    } = this.props;

    let Element = renderAs;

    // 3 is the default size, so we don't mention it explicitly.
    let s = size === 3 ? null : size;

    return (
      <Element
        {...props}
        className={classNames(className, {
          title: !subtitle && !heading,
          subtitle,
          heading,
          [`is-${s}`]: s,
          [`has-text-weight-${weight}`]: weight,
          'is-spaced': spaced && !subtitle
        })}
      >
        {children}
      </Element>
    );
  }
}

export default Heading;