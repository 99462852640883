import React from 'react';

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    const { pageTitle } = this.constructor;
    document.title = `${pageTitle} - MetyWeb`;
  }
}

export default Page;