import React from 'react';
import PropTypes from 'prop-types';
import FormatTime from './../utils/FormatTime';

const canUseIntl = Intl && Intl.RelativeTimeFormat;

class Timer extends React.PureComponent {
  static formatUnits = {
    SECOND:  'second',
    MINUTE:  'minute',
    HOUR:    'hour',
    DAY:     'day',
    WEEK:    'week',
    MONTH:   'month',
    QUARTER: 'quarter',
    YEAR:    'year'
  };

  static propTypes = {
    time: PropTypes.instanceOf(Date).isRequired,
    updateInterval: PropTypes.number.isRequired,
    formatUnit: PropTypes.oneOf(Object.values(Timer.formatUnits)),
    intlFormat: PropTypes.any
  };

  static defaultProps = {
    updateInterval: 1000,
    intlFormat: canUseIntl && new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })
  };

  state = {
    timeDiff: this.props.time.getTime() - Date.now()
  };

  componentDidMount() {
    // Only start an update interval when neccesary.
    if (!canUseIntl) return;

    this.updateInterval = setInterval(() => {
      this.setState({
        timeDiff: this.props.time.getTime() - Date.now()
      });
    }, this.props.updateInterval);
  }

  componentWillUnmount() {
    if (!canUseIntl) return;
    clearInterval(this.updateInterval);
  }

  render() {
    const {
      time,
      formatUnit,
      intlFormat
    } = this.props;

    let { timeDiff } = this.state;
    let finalFormatUnit = formatUnit;
    let timeStr;

    if (!formatUnit) {
      // Determine format unit.
      if (timeDiff > -60000) {
        finalFormatUnit = Timer.formatUnits.SECOND;
      } else if (timeDiff > -3600000) {
        finalFormatUnit = Timer.formatUnits.MINUTE;
      } else if (timeDiff > -86400000) {
        finalFormatUnit = Timer.formatUnits.HOUR;
      } else if (timeDiff > -604800000) {
        finalFormatUnit = Timer.formatUnits.DAY;
      } else if (timeDiff > -2592000000) {
        finalFormatUnit = Timer.formatUnits.WEEK;
      } else if (timeDiff > -7884000000) {
        finalFormatUnit = Timer.formatUnits.MONTH;
      } else if (timeDiff > -31536000000) {
        finalFormatUnit = Timer.formatUnits.QUARTER;
      } else {
        finalFormatUnit = Timer.formatUnits.YEAR;
      }
    }

    if (canUseIntl) {
      /*eslint no-fallthrough: 0*/
      switch(finalFormatUnit) {
        case Timer.formatUnits.YEAR:
          timeDiff /= 4;
        case Timer.formatUnits.QUARTER:
          timeDiff /= 3;
        case Timer.formatUnits.MONTH:
          timeDiff /= 365.25 / 4 / 3 / 7;
        case Timer.formatUnits.WEEK:
          timeDiff /= 7;
        case Timer.formatUnits.DAY:
          timeDiff /= 24;
        case Timer.formatUnits.HOUR:
          timeDiff /= 60;
        case Timer.formatUnits.MINUTE:
          timeDiff /= 60;
        case Timer.formatUnits.SECOND:
          timeDiff /= 1000;
        // no default
      }
      timeStr = intlFormat.format(Math.round(timeDiff), finalFormatUnit);
    } else {
      timeStr = FormatTime(time, true);
    }

    return timeStr;
  }
}

export default Timer;