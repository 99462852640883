import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NavbarContext from './../context';

class NavbarMenu extends React.PureComponent {
  static contextType = NavbarContext;

  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    const {
      className,
      children,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames('navbar-menu', className, {
          'is-active': this.context
        })}
      >
        {children}
      </div>
    );
  }
}

export default NavbarMenu;