import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Table extends React.PureComponent {
  static sizes = ['fullwidth', 'narrow'];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.oneOf(Table.sizes).isRequired,
    striped: PropTypes.bool.isRequired,
    bordered: PropTypes.bool.isRequired,
    hoverable: PropTypes.bool.isRequired
  };

  static defaultProps = {
    size: 'fullwidth',
    striped: false,
    bordered: false,
    hoverable: false
  };

  render() {
    const {
      children,
      className,
      size,
      striped,
      bordered,
      hoverable,
      ...props
    } = this.props;

    return (
      <table
        {...props}
        className={classNames('table', className, {
          [`is-${size}`]: size,
          'is-bordered': bordered,
          'is-striped': striped,
          'is-hoverable': hoverable
        })}
      >
        {children}
      </table>
    );
  }
}

export default Table;