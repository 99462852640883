import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import constants from './../constants';
import LevelSide from './components/Side';
import LevelItem from './components/Item';

class Level extends React.PureComponent {
  static Side = LevelSide;
  static Item = LevelItem;

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    breakpoint: PropTypes.oneOf(constants.BREAKPOINTS)
  };

  render() {
    const {
      children,
      className,
      breakpoint,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames('level', className, {
          [`is-${breakpoint}`]: breakpoint
        })}
      >
        {children}
      </div>
    );
  }
}

export default Level;