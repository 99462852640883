import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class LevelItem extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object
  };

  render() {
    const {
      children,
      className,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames(classNames('level-item', className))}
      >
        {children}
      </div>
    );
  }
}

export default LevelItem;