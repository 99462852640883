import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class NavbarContainer extends React.PureComponent {
  static positions = ['start', 'end'];

  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    position: PropTypes.oneOf(NavbarContainer.positions).isRequired
  };

  static defaultProps = {
    position: 'start'
  };

  render() {
    const {
      className,
      children,
      position,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames(`navbar-${position}`, className)}
      >
        {children}
      </div>
    );
  }
}

export default NavbarContainer;