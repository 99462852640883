import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class HeroPart extends React.PureComponent {
  static parts = [
    'head',
    'body',
    'foot'
  ];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    part: PropTypes.oneOf(HeroPart.parts).isRequired
  };

  render() {
    const {
      children,
      className,
      part,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames(`hero-${part}`, className)}
      >
        {children}
      </div>
    );
  }
}

export default HeroPart;