import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from './../constants';

class Tag extends React.PureComponent {
  static sizes = ['medium', 'large'];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(constants.COLORS),
    size: PropTypes.oneOf(Tag.sizes),
    rounded: PropTypes.bool.isRequired,
    remove: PropTypes.bool.isRequired
  };

  static defaultProps = {
    rounded: false,
    remove: false
  };

  render() {
    const {
      children,
      className,
      color,
      size,
      rounded,
      remove,
      ...props
    } = this.props;

    return (
      <span
        {...props}
        className={classNames('tag', className, {
          [`is-${size}`]: size,
          [`is-${color}`]: color,
          'is-rounded': rounded,
          'is-delete': remove
        })}
      >
        {!remove && children}
      </span>
    );
  }
}

export default Tag;