import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NavbarContext from './../context';

class NavbarBurger extends React.PureComponent {
  static contextType = NavbarContext;

  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    onClick: () => {}
  };

  render() {
    const {
      style,
      className,
      onClick,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        onClick={onClick}
        role='button'
        tabIndex='0'
        style={{ outline: 'none', ...style }}
        className={classNames('navbar-burger', className, {
          'is-active': this.context
        })}
      >
        <span />
        <span />
        <span />
      </div>
    );
  }
}

export default NavbarBurger;