import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/database';

firebase.initializeApp({
  apiKey: 'AIzaSyBVMCmqurtcXsjq3swukpm7uO4_2Fob6aw',
  authDomain: 'metyweb2.firebaseapp.com',
  databaseURL: 'https://metyweb2-eu1.europe-west1.firebasedatabase.app',
  projectId: 'metyweb2',
  storageBucket: 'metyweb2.appspot.com',
  messagingSenderId: '480091658900',
  appId: '1:480091658900:web:c37793e4a66aa89e1e7281'
});

ReactDOM.render(<App />, document.getElementById('root'));

// Enable server worker: http://bit.ly/CRA-PWA
serviceWorker.register();
