// Highcharts imports.
import Highcharts from 'highcharts/es-modules/parts/Globals';
// import 'highcharts/es-modules/parts/SvgRenderer.js';
// import 'highcharts/es-modules/parts/Html.js';
import 'highcharts/es-modules/parts/Axis.js';
import 'highcharts/es-modules/parts/DateTimeAxis.js';
// import 'highcharts/es-modules/parts/LogarithmicAxis.js';
// import 'highcharts/es-modules/parts/PlotLineOrBand.js';
// import 'highcharts/es-modules/parts/Tooltip.js';
import 'highcharts/es-modules/parts/Pointer.js';
import 'highcharts/es-modules/parts/TouchPointer.js';
// import 'highcharts/es-modules/parts/MSPointer.js';
// import 'highcharts/es-modules/parts/Legend.js';
import 'highcharts/es-modules/parts/Chart.js';
// import 'highcharts/es-modules/parts/ScrollablePlotArea.js';
// import 'highcharts/es-modules/parts/Stacking.js';
import 'highcharts/es-modules/parts/Dynamics.js';
// import 'highcharts/es-modules/parts/AreaSeries.js';
// import 'highcharts/es-modules/parts/SplineSeries.js';
// import 'highcharts/es-modules/parts/AreaSplineSeries.js';
// import 'highcharts/es-modules/parts/ColumnSeries.js';
// import 'highcharts/es-modules/parts/BarSeries.js';
// import 'highcharts/es-modules/parts/ScatterSeries.js';
import 'highcharts/es-modules/parts/PieSeries.js';
import 'highcharts/es-modules/parts/DataLabels.js';
import 'highcharts/es-modules/modules/overlapping-datalabels.src.js';
import 'highcharts/es-modules/parts/Interaction.js';
import 'highcharts/es-modules/parts/Responsive.js';

// Highstock imports.
import 'highcharts/es-modules/parts/OrdinalAxis.js';
// import 'highcharts/es-modules/modules/broken-axis.src'
// import 'highcharts/es-modules/parts/DataGrouping.js';
import 'highcharts/es-modules/parts/OHLCSeries.js';
// import 'highcharts/es-modules/parts/CandlestickSeries.js';
// import 'highcharts/es-modules/parts/FlagsSeries.js';
// import 'highcharts/es-modules/parts/Scrollbar.js';
// import 'highcharts/es-modules/parts/Navigator.js';
// import 'highcharts/es-modules/parts/RangeSelector.js';
import 'highcharts/es-modules/parts/StockChart.js';

export default Highcharts;