import firebase from 'firebase/app';
import localforage from 'localforage';

class MeasurementsCache extends EventTarget {
  static version = 1.0;

  measurements = {};
  databaseAvailable = false;

  /**
   * @type {LocalForage}
   */
  cache;

  constructor(name, itemLifetime) {
    super();

    const version = MeasurementsCache.version;
    this.itemLifetime = itemLifetime;
    this.cache = localforage.createInstance({ name, version });

    this.retrieve();
  }

  /**
   * Retrieve the initial data from the cache and firestore.
   */
  async retrieve() {
    const minTime = Date.now() - this.itemLifetime;
    let maxTime = minTime;

    // Retrieve items from the cache.
    await this.cache.iterate((value, key) => {
      const timestamp = value.time.getTime();

      if (timestamp >= minTime) {
        this.measurements[key] = value;
        if (timestamp > maxTime) {
          maxTime = timestamp;
        }
      } else {
        this.cache.removeItem(key);
      }
    });

    // Dispatch items before accessing firestore.
    this.dispatchItems();

    // Retrieve the remaining items from the firestore.
    const ref = firebase.database().ref('measurements');
    const query = ref.orderByKey().startAt((maxTime + 1).toString());
    query.on('value', this.onFirebaseSnapshot);
  }

  dispatchItems() {
    this.dispatchEvent(new CustomEvent('value', {
      detail: this.measurements,
    }));
  }

  onFirebaseSnapshot = async (snapshot) => {
    const items = snapshot.val();

    for (const key in items) {
      const item = items[key];
      this.measurements[key] = item;
      this.cache.setItem(key, item);
    }

    this.dispatchItems();
  }
}

export default MeasurementsCache;