import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import constants from './../constants';

class Container extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    fluid: PropTypes.bool.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    breakpoint: PropTypes.oneOf(constants.BREAKPOINTS)
  };

  static defaultProps = {
    fluid: false
  };

  render() {
    const {
      children,
      fluid,
      className,
      breakpoint,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames('container', className, {
          'is-fluid': fluid,
          [`is-${breakpoint}`]: breakpoint
        })}
      >
        {children}
      </div>
    );
  }
}

export default Container;