import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class NavbarBrand extends React.PureComponent {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const {
      className,
      children,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames('navbar-brand', className)}
      >
        {children}
      </div>
    );
  }
}

export default NavbarBrand;