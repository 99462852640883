import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from './layout/Header';
import Content from './layout/Content';

class Layout extends React.PureComponent {
  render() {
    const { location } = this.props;

    return (
      <>
        <Header location={location} />
        <Content location={location} />
      </>
    );
  }
}

export default withRouter(Layout);
