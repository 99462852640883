import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class NavbarItem extends React.PureComponent {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    active: PropTypes.bool.isRequired,
    dropdown: PropTypes.bool.isRequired,
    dropdownUp: PropTypes.bool.isRequired,
    hoverable: PropTypes.bool.isRequired,
    children: PropTypes.node,
    renderAs: PropTypes.any.isRequired
  };

  static defaultProps = {
    active: false,
    dropdown: false,
    dropdownUp: false,
    hoverable: false,
    renderAs: 'a'
  };

  render() {
    const {
      className,
      active,
      dropdown,
      dropdownUp,
      hoverable,
      children,
      renderAs,
      ...props
    } = this.props;

    const Element = renderAs;

    return (
      <Element
        {...props}
        className={classNames('navbar-item', className, {
          'is-active': active,
          'has-dropdown': dropdown,
          'has-dropdown-up': dropdownUp,
          'is-hoverable': hoverable
        })}
      >
        {children}
      </Element>
    );
  }
}

export default NavbarItem;