import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import constants from './../constants';
import HeroPart from './HeroPart';

class Hero extends React.PureComponent {
  static Part = HeroPart;
  
  static sizes = [
    'small',
    'medium',
    'large',
    'fullheight',
    'fullheight-with-navbar'
  ];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(constants.COLORS),
    gradient: PropTypes.bool,
    size: PropTypes.oneOf(Hero.sizes),
    style: PropTypes.object
  };

  static defaultProps = {
    gradient: false
  };

  render() {
    const {
      children,
      className,
      color,
      gradient,
      size,
      ...props
    } = this.props;

    return (
      <section
        {...props}
        className={classNames('hero', className, {
          [`is-${color}`]: color,
          'is-bold': gradient,
          [`is-${size}`]: size
        })}
      >
        {children}
      </section>
    );
  }
}

export default Hero;