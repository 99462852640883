import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Container from './../components/bulma/container';
import Hero from './../components/bulma/hero';
import Navbar from './../components/bulma/navbar';
import Heading from '../components/bulma/heading';

class Header extends React.PureComponent {
  state = {
    active: false
  }

  toggleMenu = () => {
    this.setState({
      active: !this.state.active
    });
  }

  closeMenu = () => {
    this.setState({
      active: false
    });
  }

  render() {
    const { location } = this.props;
    const { active } = this.state;

    return (
      <Hero color='primary'>
        <Hero.Part part='head'>
          <Navbar active={active}>
            <Container>
              <Navbar.Brand>
                <Navbar.Item renderAs={Link} to='/' onClick={this.closeMenu}>
                  <img src={process.env.PUBLIC_URL + '/static/favicon-32x32.png'} alt='MetyWeb Logo' />
                  MetyWeb
                </Navbar.Item>

                <Navbar.Burger title='Toon/verberg menu' onClick={this.toggleMenu} />
              </Navbar.Brand>
              <Navbar.Menu>
                <Navbar.Container>
                  <Navbar.Item
                    renderAs={NavLink}
                    activeClassName='is-active'
                    to='/'
                    exact
                    location={location}
                    onClick={this.closeMenu}
                  >
                    Afgelopen 24 uur
                  </Navbar.Item>
                </Navbar.Container>
              </Navbar.Menu>
            </Container>
          </Navbar>
        </Hero.Part>
        <Hero.Part part='body'>
          <Container>
            <Heading>MetyWeb</Heading>
            <Heading renderAs='h2' subtitle>Weergegevens rechtstreeks oet Ranzel</Heading>
          </Container>
        </Hero.Part>
      </Hero>
    );
  }
}

export default Header;