import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from './../constants';

class Notification extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(constants.COLORS),
    light: PropTypes.bool,
  };

  render() {
    const {
      children,
      light,
      color,
      className,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames('notification', className, {
          [`is-${color}`]: color,
          'is-light': light,
        })}
      >
        {children}
      </div>
    );
  }
}

export default Notification;