import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './../pages/home';

class Content extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
      </Switch>
    );
  }
}

export default Content;