import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class LevelSide extends React.PureComponent {
  static align = ['left', 'right'];

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    align: PropTypes.oneOf(LevelSide.align).isRequired
  };

  static defaultProps = {
    align: 'left'
  };

  render() {
    const {
      children,
      className,
      align,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={classNames(className, {
          [`level-${align}`]: align
        })}
      >
        {children}
      </div>
    );
  }
}

export default LevelSide;